.mainHeader{
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
}
.error {
    color:red;
}

@media (max-width: 600px) {


    .v-name::before {
        content: 'Vendor Name';
    }

    .email::before {
        content: 'Email Address';
    }
    .mobile::before {
        content: 'Mobile Number';
    }
    .phone::before {
        content: 'Phone Number';
    }
    .pan::before {
        content: 'PAN Number';
    }
    .address::before {
        content: 'Address';
    }


}