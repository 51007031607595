.feedback {
    background-color: #31B0D5;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    border-color: #46b8da;
    z-index: 99999;
}

#mybutton {
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 99999;
}

.floatingIcon {
    position: relative;
    top: 40px;
    padding: 30px;
    background: #db3d44;
    background: url("../../Assets/Images/logo_icon.png") no-repeat 50%/50% #db3d44;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.15), -7px -7px 20px rgba(255, 255, 255, 1), inset 0px 0px 4px rgba(255, 255, 255, 0.2), inset 7px 7px 15px rgba(55, 84, 170, 0), inset -7px -7px 20px rgba(255, 255, 255, 0), 0px 0px 4px rgba(255, 255, 255, 0);
}
/* .menu::before,
.menu::after {
    content: "";
    background: #c3c2c7;
    border-radius: 5px;
    width: 30px;
    height: 5px;
    position: absolute;
    left: 16px;
    top: 27px;
    transition: 0.2s ease;
    z-index: 99999;
} */

/* .menu::before {
    transform: rotate(0deg);
}

.menu::after {
    transform: rotate(-90deg);
} */

/* .menu.open {
    opacity: 0.9;
}

.menu.open::before {
    transform: rotate(45deg);
}

.menu.open::after {
    transform: rotate(-45deg);
} */

.floatingIcon.open .button {
    opacity: 1;
    pointer-events: auto;
}

.floatingIcon.open .button:first-of-type {
    bottom: 10px;
    right: 70px;
    background: url("../../Assets/Images/logo_icon.png") no-repeat 50%/50% #31B0D5;
}

.floatingIcon.open .button:nth-of-type(2) {
    bottom: 75px;
    right: 50px;
    background: url("https://bassets.github.io/img.svg") no-repeat 50%/ 50% #31B0D5;
    transition-delay: 0.05s;
    z-index: 99999;
}

.floatingIcon.open .button:nth-of-type(3) {
    bottom: 75px;
    right: -30px;
    background: url("../../Assets/Images/user_profile.png") no-repeat 50%/ 50% #31B0D5;
    transition-delay: 0.05s;
    z-index: 99999;
}


.floatingIcon.open .button:last-of-type {
    bottom: 10px;
    right: -70px;
    background: url("../../Assets/SVG/logout.svg") no-repeat 50% 45%/50% 45% #31B0D5;
    transition-delay: 0.1s;
}

.button {
    padding: 30px;
    border-radius: 50%;
    cursor: pointer;
    background: #31B0D5;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    box-shadow: inherit;
    transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity, 0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
}

.button:hover {
    transform: scale(1.1);
}