@media (max-width: 600px) {
    .email::before {
        content:  'Email';
    }
    .mobile::before {
        content:  'Mobile Number';
    }
    .message::before {
        content:  'Message';
    }
    .type::before {
        content:  'Type';
    }
    .date::before {
        content:  'Date';
    }
}