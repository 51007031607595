.modal-90w {
  width: 90%;
  max-width: none!important;
}
.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.error .mandatory {
  color:red;
}

.CheckboxGroup label{
  margin-right: 10px;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .st-name::before {
    content: 'Station Name';
  }
  .cp::before {
      content: 'Contact Person';
  }
  .ce::before {
    content: 'Contact Email';
  }
  .address::before {
    content: 'Address';
  }
  /* .ot::before {
    content: 'Order Timing';
  }
  .mo::before {
    content: 'Min Order';
  }
  .dc::before {
    content: 'Delivery Cost';
  }
  .prepaid::before {
    content: 'Prepaid';
  } */
  .state::before {
    content: 'State';
  }
  .city::before {
    content: 'City';
  }
  /*.op::before {
    content: 'Opening Time';
  }
  .ct::before {
    content: 'Closing Time';
  }
   .gst::before {
    content: 'GST NO';
  }
  .fssai::before {
    content: 'FSSAI NO';
  }
  .fvu::before {
    content: 'FSSAI Valid Upto';
  }
  .cperiod::before {
    content: 'Closing Period';
  }
  .wc::before {
    content: 'Weekly Closed';
  }
  .vn::before {
    content: 'Vendor Name';
  }
  .ve::before {
    content: 'Vendor EmailID';
  }
  .vm::before {
    content: 'Vendor Mobile Number';
  }
  .vp::before {
    content: 'Vendor PAN Number';
  } */
  .ot,.mo,.dc,.prepaid,.op,.ct,.gst,.fssai,.fvu,.cperiod,.wc,.vn,.ve,.vm,.vp{
    display: none !important;
  }
  
}