.mainHeader{
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
}
.error {
    color:red;
}

@media (max-width: 600px) {
    .on::before {
        content: 'Outlet Name';
    }
    .name::before {
        content: 'Name';
    }
    .ea::before {
        content: 'Email Address';
    }
    .mobile::before {
        content: 'Mobile Number';
    }
}