.logo-container {
  border-radius: .5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 76px;
  padding: 1em;
  text-align: left;
  max-width: 420px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}

.logo-icon {
  width: 120px;
  height: 120px;
  margin: -70px auto 20px auto;
}
 
.form {
  padding: 1em;
}
 
label {
  font-weight: 600;
}

.body {
  margin: 0;
  padding: 0;
  background-color: #b81c17;
}

@media screen and (max-width: 600px) {
  .logo-container{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 100px;
  }
}