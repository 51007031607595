.header-class{
    background-color: rgb(248, 249, 250);
    color:#2f3746;
}

.evenClass{
    background-color: #ffffff;
}

.oddClass{
    background-color: #ffffff;
}
.react-bootstrap-table{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    margin-top: 15px;
  }
.react-bs-table-csv-btn btn btn-default {
    background-color: #3586c1 !important;
}
.react-bootstrap-table .table>:not(caption)>*>*{
    padding: 0.5rem 1rem;
}
.react-bootstrap-table th{
    padding: 1rem 1rem;
}
.react-bootstrap-table-page-btns-ul .active>.page-link, .react-bootstrap-table-page-btns-ul .page-link.active{
    background-color: rgb(99, 102, 241);
    color: #FFF;
}
.react-bootstrap-table-page-btns-ul .page-link{
    color: #111927;
}
.react-bs-table-sizePerPage-dropdown .btn-secondary{
    border: 1px solid #ced4da;
    background-color: #FFF;
    color: #111927;
    margin-right: 5px;
    line-height: normal;
}
.react-bs-table-sizePerPage-dropdown .btn-secondary:focus{
    box-shadow: none;
    outline: none;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item{
    font-size: 12px;
    line-height: normal;
}
.react-bootstrap-table table{
    margin-bottom: 0px;
}
.react-bootstrap-table th {
    vertical-align: top;
}

@media screen and (max-width: 600px) {

    .react-bootstrap-table tr {
        display: block;
        margin-bottom: 10px; 
        padding: 10px;
        border-radius: 10px;
        border: solid 1px red;
        position: relative;
      }
      .react-bootstrap-table td {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

    .react-bootstrap-table {
        box-shadow:none;
    }

    .react-bootstrap-table-pagination-list{
        margin-top: 15px;
    }
    .react-bootstrap-table .table>:not(caption)>*>*{
        padding: 0.25rem 1rem;
    }
    .react-bootstrap-table-page-btns-ul {
        float: left;
        margin-top: 0!important;
    }

    .react-bootstrap-table thead{
        display: none;
    }
    .table-bordered>:not(caption)>*>*{
        border-width: 0;
    }
    .react-bs-table-sizePerPage-dropdown .btn-secondary, .react-bootstrap-table-pagination-total, .react-bootstrap-table-page-btns-ul .page-link{
        font-size: 12px;
    }
}