.switch.btn {
    width: 6.7rem !important;
}

@media (max-width: 600px) {


    .st-name::before {
        content: 'Station Name';
    }

    .st-code::before {
        content: 'Station Code';
    }


}