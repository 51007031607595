.itemTypeIcon{
    width:20px;
    height:20px
}
.item-type-image img{
    max-width:20px;
    max-height:20px
}
.modal-90w {
    width: 90%;
    max-width: none!important;
}

@media (max-width: 600px) {

    .item-name::before {
        content: 'Item Name';
    }
    .item-price::before {
        content: 'Item Price';
    }
    .name::before {
        content: 'Station';
    }
    .base-price::before {
        content: 'Base Price';
    }
    .tax::before {
        content: 'Tax';
    }
    .selling-price::before {
        content: 'Selling Price';
    }
    .tags::before {
        content: 'Tags';
    }
    .available::before {
        content: 'Is available';
    }
    .gst::before {
        content: 'GST';
    }
    .cuisine::before {
        content: 'Cuisine';
    }
    .food-type::before {
        content: 'Food Type';
    }
    .new-item-price::before {
        content: 'New Item Price';
    }
    .item-type::before {
        content: 'Item Type';
    }
    .menu-dropdown{
        flex-direction: column;
        width: 100% !important;
    }
    .menu-dropdown .m-2{
        margin: 0 !important;
    }
    .menu-dropdown .m-2 .form-select{
        margin-bottom: 15px;
    }
    .item-type{
        padding-bottom: 20px !important;
    }
}