@media (max-width: 600px) {
    .order_id::before {
        content:  'Order Id';
    }
    .consumer_name::before{
        content: 'Consumer Name';
    }
    .email::before {
        content:  'Email';
    }
    .mobile::before {
        content:  'Mobile Number';
    }
    .amt::before {
        content:  'Total Amt';
    }
    .booking_date::before {
        content:  'Booking Date';
    }
    .delivery_date::before {
        content:  'Delivery Date';
    }
    .vendor_name::before {
        content:  'Vendor Name';
    }
    .status::before {
        content:  'Order Status';
    }
    .status{
        padding-bottom: 10px !important;
    }
}