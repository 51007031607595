
@media (max-width: 600px) {
    .order_id::before {
        content:  'Order Id';
    }
    .consumer_name::before{
        content: 'Consumer Name';
    }
    .email::before {
        content:  'Email';
    }
    .mobile::before {
        content:  'Mobile Number';
    }
    .amt::before {
        content:  'Total Amt';
    }
    .booking_date::before {
        content:  'Booking Date';
    }
    .delivery_date::before {
        content:  'Delivery Date';
    }
    .vendor_name::before {
        content:  'Vendor Name';
    }
    .status::before {
        content:  'Order Status';
    }
    .payment_mode::before{
        content:  'Payment Mode';
    }
    .status{
        padding-bottom: 10px !important;
    }
   
}
.underline{
    text-decoration:underline;
    text-decoration-style: dotted;
}

table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
}
 
th {
    border-bottom: 1px solid black;
}
 
td {
    text-align: center;
}