@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  color: #111927;
  line-height: 21px;
}

.text-dark-600 {
  color: #6c737f;
}

.custom-search-field {
  color: #111927;
}

.sidebar {
  width: 100%;
  color: #dcdcdc;
  padding-top: 20px;
  padding-left: 20px;
}

.sidebar__items {
  display: flex;
  flex-direction: column;

}

.sidebar__items a {
  color: #EBF8FE;
  font-size: 20px;
  margin-bottom: 20px;
  text-decoration: none;
}

.sidebar_active {
  text-decoration: underline !important;
}

.labeltextcolor {
  color: #464855
}

.header {
  background-color: #FFF;
  padding: 15px 30px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  display: flex;
  justify-content: space-between;
}

img {
  max-width: 100%;
}

.vendor-name {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}

.vendor-name img {
  margin-left: 5px;
  max-height: 24px;
}

.sidenav---sidenav---_2tBP {
  position: fixed;
}

.td-logo {
  padding: 20px;
  max-width: 220px;
}

.td-logo img {
  max-width: 100%;
}

main {
  position: relative;
}

.page-container {
  padding: 30px;
  min-height: calc(100vh - 145px);
}

.header-logo img {
  max-height: 36px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 15px 0px;
}

.page-title {
  font-size: 33px;
  font-weight: 700;
}

.action-btns a {
  width: 24px;
  height: 24px;
  background-color: #eceff6;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  color: #6c737f !important;
  cursor: pointer;
}

.footer {
  background-color: #FFF;
  text-align: center;
  padding: 15px 30px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  color: #6c737f;
  width: 100%;
  /* position: absolute;
  bottom: 0;
  left: 0; */
}

.btn-primary {
  background-color: rgb(99, 102, 241);
}

.td-shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}

.db-box-item {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.db-box-item .label {
  color: #6c737f;
}

.db-box-item .number {
  font-weight: 700;
  font-size: 33px;
  margin-top: 12px;
}

.card {
  border: none;
}

.card-header {
  background-color: rgb(248, 249, 250);
  color: #2f3746;
  font-size: 16px;
  font-weight: 700;
  border: none;
  line-height: 28px;
}

.mh-250 {
  min-height: 350px;
}

.profile-menu {
  position: relative;
  display: inline-block;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
}

.profile img {
  border-radius: 50%;
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 35px;
  right: 0px;
  background-color: #FFF;
  min-width: 160px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 6px 0px;
}

.profile-menu:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  color: inherit;
}

.dropdown-content a:hover {
  background-color: #dfe4ec;
}

.add-menuitems-form .w-50,
.add-outlet-user-form .w-50 {
  margin-bottom: 15px;
}


.mobile-td::-webkit-scrollbar {
  height: 1px;
}

.mobile-td::-webkit-scrollbar-track {
  background: #FFF;
}

.mobile-td::-webkit-scrollbar-thumb {
  background: #e2dcdc;
  border-radius: 5px;
}

.mobile-td::-webkit-scrollbar-thumb:hover {
  background: #ded5d5;
}

.mobile-td {
  overflow-x: scroll;
  position: relative;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  height: 40px;
  line-height: 40px;
}

.data-push-btns {
  margin-top: 25px;
}


@media screen and (max-width: 920px) {

  .jScRpv,
  .kpPeMT {
    margin-left: 64px !important;
  }

  .sidenav---sidenav-nav---3tvij {
    height: calc(100vh - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 601px) {
  .react-bootstrap-table {
    width: calc(100vw - 140px);
    overflow-x: scroll;
    margin-bottom: 15px;
  }

  .menuItemsPage .react-bootstrap-table {
    overflow: unset !important;
  }
}

@media screen and (max-width: 600px) {

  .card {
    margin-bottom: 15px;
  }

  .mh-250 {
    min-height: auto;
  }

  .header {
    padding: 15px;
  }

  .page-container {
    padding: 15px;
  }

  .page-title {
    font-size: 24px;
  }

  .db-box-item {
    margin-bottom: 15px;
  }

  .add-outlet-user-form .w-50,
  .add-menuitems-form .w-50 {
    width: 100% !important;
  }

  .profile span {
    display: none;
  }

  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-label {
    width: 100%;
  }

  .react-bootstrap-table tr {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    border: none;
  }

  .mobile-td:before {
    font-size: 12px;
    color: #8495b4;
    position: absolute;
    left: 16px;
    top: 0;
  }

  .mobile-td {
    padding-top: 20px !important;
    position: relative;
  }

  .xs-btn-block {
    width: 100%;
  }

  .data-push-btns {
    margin-top: 0px;
  }

}